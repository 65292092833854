<script setup lang="ts">
const { devState } = useDeveloperTools()
function hideDevTools() {
  devState.value = {
    loading: false,
    empty: false,
    loadingDelay: 0,
    expanded: false,
    hidden: true,
  }
}
</script>
<template>
  
</template>
